@media (min-width: 1250px) {
    .col-lg-1 {
        width: 8.33%;
    }

    .col-lg-2 {
        width: 16.66%;
    }

    .col-lg-3 {
        width: 25%;
    }

    .col-lg-4 {
        width: 33.33%;
    }

    .col-lg-5 {
        width: 42.66%;
    }

    .col-lg-6 {
        width: 50%;
    }

    .col-lg-7 {
        width: 58.33%;
    }

    .col-lg-8 {
        width: 66.66%;
    }

    .col-lg-9 {
        width: 75%;
    }

    .col-lg-10 {
        width: 83.33%;
    }

    .col-lg-11 {
        width: 92.66%;
    }

    .col-lg-12 {
        width: 100%;
    }
}

@media (max-width: 1250px) and (min-width: 750px) {
    .col-md-1 {
        width: 8.33% !important;
    }

    .col-md-2 {
        width: 16.66% !important;
    }

    .col-md-3 {
        width: 25% !important;
    }

    .col-md-4 {
        width: 33.33% !important;
    }

    .col-md-5 {
        width: 42.66% !important;
    }

    .col-md-6 {
        width: 50% !important;
    }

    .col-md-7 {
        width: 58.33% !important;
    }

    .col-md-8 {
        width: 66.66% !important;
    }

    .col-md-9 {
        width: 75% !important;
    }

    .col-md-10 {
        width: 83.33% !important;
    }

    .col-md-11 {
        width: 92.66% !important;
    }

    .col-md-12 {
        width: 100% !important;
    }
}


@media (max-width: 750px) and (min-width: 450px) {
    .col-sm-1 {
        width: 8.33% !important;
    }

    .col-sm-2 {
        width: 16.66% !important;
    }

    .col-sm-3 {
        width: 25% !important;
    }

    .col-sm-4 {
        width: 33.33% !important;
    }

    .col-sm-5 {
        width: 42.66% !important;
    }

    .col-sm-6 {
        width: 50% !important;
    }

    .col-sm-7 {
        width: 58.33% !important;
    }

    .col-sm-8 {
        width: 66.66% !important;
    }

    .col-sm-9 {
        width: 75% !important;
    }

    .col-sm-10 {
        width: 83.33% !important;
    }

    .col-sm-11 {
        width: 92.66% !important;
    }

    .col-sm-12 {
        width: 100% !important;
    }
}


@media (max-width: 450px) {
    .col-xs-1 {
        width: 8.33% !important;
    }

    .col-xs-2 {
        width: 16.66% !important;
    }

    .col-xs-3 {
        width: 25% !important;
    }

    .col-xs-4 {
        width: 33.33% !important;
    }

    .col-xs-5 {
        width: 42.66% !important;
    }

    .col-xs-6 {
        width: 50% !important;
    }

    .col-xs-7 {
        width: 58.33% !important;
    }

    .col-xs-8 {
        width: 66.66% !important;
    }

    .col-xs-9 {
        width: 75% !important;
    }

    .col-xs-10 {
        width: 83.33% !important;
    }

    .col-xs-11 {
        width: 92.66% !important;
    }

    .col-xs-12 {
        width: 100% !important;
    }
}




/* Margin */

.m-1px {
    margin: 1px;
}

.m-2px {
    margin: 2px;
}

.m-3px {
    margin: 3px;
}

.m-4px {
    margin: 4px;
}

.m-5px {
    margin: 5px;
}

.m-10px {
    margin: 10px;
}

.m-15px {
    margin: 15px;
}

.m-20px {
    margin: 20px;
}

.m-25px {
    margin: 25px;
}

.m-30px {
    margin: 30px;
}

.m-50px {
    margin: 50px;
}






.ml-1px {
    margin-left: 1px;
}

.ml-2px {
    margin-left: 2px;
}

.ml-3px {
    margin-left: 3px;
}

.ml-4px {
    margin-left: 4px;
}

.ml-5px {
    margin-left: 5px;
}

.ml-10px {
    margin-left: 10px;
}

.ml-15px {
    margin-left: 15px;
}

.ml-20px {
    margin-left: 20px;
}

.ml-25px {
    margin-left: 25px;
}

.ml-30px {
    margin-left: 30px;
}

.ml-50px {
    margin-left: 50px;
}





.mr-1px {
    margin-right: 1px;
}

.mr-2px {
    margin-right: 2px;
}

.mr-3px {
    margin-right: 3px;
}

.mr-4px {
    margin-right: 4px;
}

.mr-5px {
    margin-right: 5px;
}

.mr-10px {
    margin-right: 10px;
}

.mr-15px {
    margin-right: 15px;
}

.mr-20px {
    margin-right: 20px;
}

.mr-25px {
    margin-right: 25px;
}

.mr-30px {
    margin-right: 30px;
}

.mr-50px {
    margin-right: 50px;
}





.mt-1px {
    margin-top: 1px;
}

.mt-2px {
    margin-top: 2px;
}

.mt-3px {
    margin-top: 3px;
}

.mt-4px {
    margin-top: 4px;
}

.mt-5px {
    margin-top: 5px;
}

.mt-10px {
    margin-top: 10px;
}

.mt-15px {
    margin-top: 15px;
}

.mt-20px {
    margin-top: 20px;
}

.mt-25px {
    margin-top: 25px;
}

.mt-30px {
    margin-top: 30px;
}

.mt-50px {
    margin-top: 50px;
}




.mb-1px {
    margin-bottom: 1px;
}

.mb-2px {
    margin-bottom: 2px;
}

.mb-3px {
    margin-bottom: 3px;
}

.mb-4px {
    margin-bottom: 4px;
}

.mb-5px {
    margin-bottom: 5px;
}

.mb-10px {
    margin-bottom: 10px;
}

.mb-15px {
    margin-bottom: 15px;
}

.mb-20px {
    margin-bottom: 20px;
}

.mb-25px {
    margin-bottom: 25px;
}

.mb-30px {
    margin-bottom: 30px;
}

.mb-50px {
    margin-bottom: 50px;
}




.m-0{
    margin: 0;
}

.mt-0{
    margin-top: 0;
}

.mb-0{
    margin-bottom: 0;
}





/* Padding */

.p-0{
    padding: 0;
}


.p-1px{
    padding: 1px;
}

.p-2px{
    padding: 2px;
}

.p-3px{
    padding: 3px;
}

.p-4px{
    padding: 4px;
}

.p-5px{
    padding: 5px;
}






.mx-auto{
    margin-left: auto;
    margin-right: auto;
}

.my-auto{
    margin-top: auto;
    margin-bottom: auto;
}



.text-center{
    text-align: center;
}