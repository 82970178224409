.popup-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(106, 106, 106, 0.5);
    overflow: auto;
}

.popup-body {
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    padding: 30px;
    margin-top: 15vh;
}