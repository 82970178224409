.m-0 {
    margin: 0;
}

.d-flex {
    display: flex;
}

.btn-add {
    padding: 1.5vh;
    border-radius: 1vh;
    border: 1px solid gray;
    background-color: rgb(17, 190, 187);
    color: white;
}

.hidden {
    display: none;
}

.form-group {
    display: flex;
    padding: 10px 0px;
}

.form-control {
    width: -webkit-fill-available;
    padding: 10px;
    font-size: 12px;
    border-radius: 10px;
    border: 1px solid lightgray;
}

.float-right {
    float: right;
}

.btn-save {
    color: white;
    background-color: rgb(36, 186, 36);
    border-radius: 5px;
    padding: 5px;
    border: 1px solid rgb(108, 108, 108);
    width: 75px;
}

.btn-cancel {
    border-radius: 5px;
    padding: 5px;
    border: 1px solid rgb(108, 108, 108);
    width: 75px;
}

.w-full {
    width: 100%;
}

.menu-title {
    font-size: 14px;
}

.active {
    color: white;
    /* background-color: rgb(3, 187, 243) !important; */
    background-color: #3a8fcc !important;
}

.deactive {
    color: white;
    /* background-color: rgb(178, 233, 255) !important; */
    background-color: #9fd7ff !important;
}

.tab {
    border-radius: 5px;
    padding: 10px;
    display: block;
    margin: 10px;
    color: white;
}

.tab-active {
    background-color: #323b48;
}

.tab:hover {
    background-color: #323b48;
}

.align-right {
    margin-left: auto;
}

.content-panel {
    height: 84vh;
    padding: 20px;
}

.li-logout {
    position: fixed;
    top: 10vh;
    right: 3vw;
    box-shadow: 2px 2px 2px 2px gray;
    border-radius: 10px;
    padding: 10px 10px;
    background-color: white;
}

.li-logout:hover {
    background-color: rgb(237, 237, 237);
}

.btn-print {
    padding: 10px;
    margin-left: 10px;
    background-color: rgb(44, 25, 102);
    color: white;
    border-radius: 5px;
    width: 75px;
}

.btn-edit {
    padding: 10px;
    margin-left: 10px;
    background-color: #2574ab;
    color: white;
    border-radius: 5px;
    border: 1px solid rgb(167, 167, 167);
}

.btn-delete {
    padding: 10px;
    margin-left: 10px;
    background-color: #dc3545;
    color: white;
    border-radius: 5px;
    border: 1px solid rgb(167, 167, 167);
}

.img-logo {
    max-width: 200px;
    max-height: 100px;
}

.btn-download-all {
    padding: 10px;
    background-color: rgb(102, 7, 165);
    color: white;
    border: 1px solid gray;
    border-radius: 10px;
}

.asamplefile {
    padding: 10px;
    background-color: rgb(112, 165, 7);
    color: white;
    font-size: 13px;
    border: 1px solid gray;
    border-radius: 10px;
}


.page-tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;
}
.page-tab {
    padding: 8px 16px;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
}
.tab-active {
    background-color: #3b82f6;
    color: white;
}
.tab-inactive {
    background-color: #d1d5db;
    color: black;
}