.custom-radio {
    position: relative;
    padding-left: 30px;
    margin-right: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
}

.custom-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-radio-button {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}

.custom-radio input:checked+.custom-radio-button {
    background-color: #2196F3;
}

.custom-radio input +.custom-radio-button {
    background-color: #9e9e9e;
}

.custom-radio input +.custom-radio-button:after {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fff;
}